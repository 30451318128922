<template>
  <div>
    <div class="mx-3 mt-2 cursor-pointer" @click="$router.push('/branchpoint')">
      <span class="mr-2"
        ><font-awesome-icon :icon="['fas', 'chevron-left']" /></span
      >{{ typeName }}
    </div>
    <PackageView v-if="type == 'package'" :packageLog="packageLog" />
    <CreditView v-else-if="type == 'credit'" :creditLog="creditLog" />
  </div>
</template>
<script>
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb.vue";
const moment = require("moment");
import OtherLoading from "@/components/other/OtherLoading";
import PackageView from "./package";
import CreditView from "./credit";
export default {
  components: {
    Breadcrumb,
    OtherLoading,
    PackageView,
    CreditView
  },
  data() {
    return {
      type: this.$route.params.mode,
      typeName:
        this.$route.params.mode == "package" ? "แพ็คเกจ" : "เครดิตคงเหลือ",
      packageLog: {
        package_data: [],
        total_package: 0
      },
      creditLog: {
        remaining_credit: 0,
        logcredit: []
      }
    };
  },
  async created() {
    if (this.type == "package") await this.getPackageLog();
    else await this.getCreditLog();
  },
  methods: {
    dateFormat(value) {
      return moment(value).locale("th").format("DD MMM YYYY");
    },
    async getPackageLog() {
      this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/Service/package_log`)
        .then(data => {
          this.packageLog = data.detail;
        });
    },
    async getCreditLog() {
      this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/Service/credit_log`)
        .then(data => {
          this.creditLog = data.detail;
        });
    }
  }
};
</script>

<style lang="scss">
.total-point {
  background-color: #fff;
  color: #000;
  border-radius: 0.25rem;
  padding: 10px;
  box-shadow: 0px 0px 6px -3px black;
}
.date {
  font-size: var(--text-sm);
  &.expire {
    color: rgb(182, 11, 45);
  }
  &.create {
    color: rgb(194, 194, 194);
  }
}
.package-container {
  background-color: #e5e5e5;
  padding: 7px 10px;

  border-radius: 5px;
}
.add-credit {
  color: green;
}
.use-credit {
  color: red;
}
</style>
