<template>
  <b-container fluid class="mt-3">
    <div class="total-point">
      <b-row>
        <b-col>
          <font-awesome-icon :icon="['fas', 'gift']" />
          <span class="ml-2"> เครดิตคงเหลือ </span>
        </b-col>
        <b-col class="text-right">
          <span class="point-count"
            >{{ creditLog.remaining_credit | numeral("0,0") }} บาท</span
          >
        </b-col>
      </b-row>
    </div>
    <b-card class="border-none card-shadow mt-3">
      <div class="content-between">
        <div>วันที่</div>
        <div class="text-right">จำนวน</div>
      </div>
      <div class="row mt-3" style="row-gap: 10px">
        <div
          v-for="credit of creditLog.logcredit"
          :key="credit.id"
          class="col-12"
        >
          <div class="package-container">
            <div class="content-between">
              <div class="ft-14 color-gray">
                {{ dateFormat(credit.created_time) }}
              </div>
              <div class="text-right">
                <div class="add-credit" v-if="credit.use_credit == 0">
                  + {{ credit.add_credit | numeral("0,0") }}
                </div>
                <div class="use-credit" v-else-if="credit.add_credit == 0">
                  - {{ credit.use_credit | numeral("0,0") }}
                </div>
                <div class="ft-14 color-gray">{{ credit.branch_name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </b-container>
</template>

<script>
const moment = require("moment");
export default {
  props: {
    creditLog: {
      required: true,
      default: {
        remaining_credit: 0,
        logcredit: []
      }
    }
  },
  methods: {
    dateFormat(value) {
      return moment(value).locale("th").format("DD MMM YYYY");
    }
  }
};
</script>

<style></style>
