<template>
  <b-container fluid class="mt-3">
    <div class="total-point">
      <b-row>
        <b-col>
          <font-awesome-icon :icon="['fas', 'gift']" />
          <span class="ml-2"> แพ็คเกจคงเหลือ </span>
        </b-col>
        <b-col class="text-right">
          <span class="point-count"
            >{{ packageLog.total_package | numeral("0,0") }} แพ็คเกจ</span
          >
        </b-col>
      </b-row>
    </div>
    <b-card class="border-none card-shadow mt-3">
      <div class="content-between">
        <div>ขื่อแพ็คเกจ/<br />วันที่ซื้อ</div>
        <div class="text-right">จำนวนครั้งที่ใช้ได้/<br />วันหมดอายุ</div>
      </div>
      <div class="row mt-3" style="row-gap: 10px">
        <div
          v-for="packageItems of packageLog.package_data"
          :key="packageItems.product_id"
          class="col-12"
        >
          <div class="package-container">
            <div class="content-between">
              <div>{{ packageItems.product_name }}</div>
              <div>
                {{ packageItems.outstanding_qty }}/{{ packageItems.total_qty }}
              </div>
            </div>
            <div class="content-between">
              <div class="ft-14 color-gray">
                {{ dateFormat(packageItems.created_time) }}
              </div>
              <div class="ft-14 date expire">
                {{ dateFormat(packageItems.expire_date_time) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </b-container>
</template>

<script>
const moment = require("moment");
export default {
  props: {
    packageLog: {
      required: true,
      default: {
        package_data: [],
        total_package: 0
      }
    }
  },
  methods: {
    dateFormat(value) {
      return moment(value).locale("th").format("DD MMM YYYY");
    }
  }
};
</script>

<style></style>
